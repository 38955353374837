import HeaderCom from '../../../components/Header.vue';

export default {
  name: 'applyPay',
  components: {
    HeaderCom
  },
  data() {
    return {
      total: 0, // 总条目数
      currentPage: 1, // 当前页
      limit: 10, // 每页显示数量
      loading: false,
      rowId: '',
      formInline: {},
      specialList: [],
      levelList: [],
      payList: [{ value: '0', label: '未缴费' }, { value: '1', label: '已缴费' }],
      tabColumns: [
        { title: '序号', type: 'index', width: 50, align: 'center' },
        { title: '姓名', key: 'regName', align: 'center' },
        { title: '性别', key: 'gender', width: 60, align: 'center' },
        { title: '身份证号', key: 'idNumber', align: 'center', tooltip: true },
        { title: '联系电话', key: 'tel', align: 'center', tooltip: true },
        { title: '测试专业名称', key: 'examMajor', align: 'center', tooltip: true },
        { title: '测试级别', key: 'examMajorTitle', align: 'center' },
        {
          title: '审核状态', key: 'workflowStatus', align: 'center',
          render: (h, params) => {
            let tmpStr = "";
            if (params.row.workflowStatus == 0) {
              tmpStr = "未提交";
            } else if (params.row.workflowStatus == 100) {
              tmpStr = "待审核"; // #2d8cf0
            } else if (params.row.workflowStatus == 150) {
              tmpStr = "审核不通过"; // #ed3f14
            } else {
              tmpStr = "审核通过"; // #19be6b
            }
            return h('span', {
              style: {
                color: (params.row.workflowStatus == 100) ? "#2d8cf0" : (params.row.workflowStatus == 150 ? "#ed3f14" : (params.row.workflowStatus == 200 ? "#19be6b" : "#ff9900"))
              }
            }, tmpStr)
          }
        },
        {
          title: '缴费状态', key: 'regPay', align: 'center',
          render: (h, params) => {
            let tmpStr = "";
            if (params.row.regPay == 1) {
              tmpStr = "已缴费";
            } else {
              tmpStr = "未缴费";
            }
            return h('span', {
              style: {
                color: (params.row.regPay == 0 || 'null') ? "#ed3f14" : "#19be6b"
              }
            }, tmpStr)
          }
        },
        { title: '缴费时间', key: 'payTime', align: 'center' },
        { title: '平台订单号', key: 'orderNo', align: 'center', tooltip: true },
        { title: '支付宝订单号', key: 'transactionSn', align: 'center' },
        { title: '操作', slot: 'action', width: 120, align: 'center' },
      ],
      tabData: [
        {
          name: 'John Brown',
          age: 18,
          sex: '女',
          idcard: '646546546546546546',
          phone: '16465465465',
          special: '水利水电',
          grade: '级别一',
          state: '审核状态',
          pay: '已缴费',
          time: '2020-09-07',
          order: '654654654654',
          alipay: '3232465464'
        }],
      payModal: false,
    }
  },
  mounted() {
    this.getData();
    this.specialList = JSON.parse(sessionStorage.getItem('specialList'));
    this.levelList = JSON.parse(sessionStorage.getItem('levelList'));
  },
  methods: {
    // 表单清空
    resetForm(fileName) {
      this.$refs[fileName].resetFields();
    },
    onSearch() {
      this.currentPage = 1;
      // this.$refs.page.init();
      this.getData();
    },
    // 页码改变的回调，返回改变后的页码(当前页)
    sizeChange(val) {
      this.currentPage = val;
      this.getData();
    },
    // 切换每页条数时的回调，返回切换后的每页条数(每页数)
    pageChange(val) {
      console.log(val, 'meiyeshu')
      this.limit = val;
      this.getData();
    },
    getData() {
      this.loading = true;
      this.$http.payRecord({
        pageNum: this.currentPage,
        pageSize: this.limit,
        examMajor: this.formInline.examMajor,
        examMajorTitle: this.formInline.examMajorTitle,
        regPay: this.formInline.regPay,
      }).then(data => {
        this.loading = false;
        if (data.code == '200') {
          this.tabData = data.data.data;
          this.total = data.data.total;
        } else {
          this.$Message.error(data.msg);
        }
      }).catch(error => {

      })
    },
    // 缴费
    payBtn(row) {
      // this.payModal = true;
      this.rowId = row.regId;
      this.examMajorTitle = row.examMajorTitle;
      let routeUrl = this.$router.resolve({
        path: '/apply',
        query: {
          id: this.rowId,
          examMajorTitle: this.examMajorTitle
        }
      });
      // window.open(routeUrl.href);
      window.location.href = routeUrl.href;
    }
  }
}